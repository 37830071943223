import React, { useState, useEffect, useRef } from 'react';

import { useResizeObserver } from 'usehooks-ts';
import { Dayjs } from 'dayjs';

import { Table, Modal, Tag, Select, DatePicker, notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    LoadingOutlined,
    CloseOutlined,
    ReloadOutlined,
    MinusOutlined,
    PlusOutlined,
    UndoOutlined,
    FilterFilled,
    SwapOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';

import { exception, securityRestriction, warning } from '@extensions/notification';
import { delayAction, toFinanceString, getEnumList } from '@extensions/utils';
import { userLoaded, setFilter } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';

import { serverFetch } from '@src/core/server';

import { ITransaction } from '@entities/transaction';
import { ITransactionFilter } from '@entities/transaction-filter';
import { IUserSession } from '@entities/user-session';

import { Currency } from '@enums/currency';
import { TransactionType, enumLabel as TransactionTypeLabel } from '@enums/transaction-types';
import { IEnumItem } from '@enums/enum-item';
import { OperationType, enumLabel as OperationTypeLabel } from '@enums/operation-type';
import { Permission, hasPermission } from '@enums/permission';
import { TransactionStatus } from '@enums/transaction-status';

import { RubleFilledIcon, UsdFilledIcon, EuroFilledIcon } from '@icons/filled/index';

const dayjs = require('dayjs');

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

interface ITransactionsControl {
    userId: string;
    hideInitiator?: boolean;
    isViewMode: boolean;
    onRefresh?: () => void;
}

const filterContext: string = 'Transactions';

const Transactions = (props: ITransactionsControl) => {
    const { userId, isViewMode, hideInitiator, onRefresh } = props;

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const initFilter: ITransactionFilter = {
        userId: userId,
        statuses: [TransactionStatus.Pending, TransactionStatus.Authorised],
        createdFrom: dayjs().subtract(2, 'month').utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString(),
    };

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<ITransactionFilter>((s) => s.filters[filterContext]);

    const { RangePicker } = DatePicker;

    const [modal, modalContextHolder] = Modal.useModal();
    const [api, contextHolder] = notification.useNotification();
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [currentTransaction, setCurrentTransaction] = useState<ITransaction>();
    const d = useAppDispatch();

    const [transactions, setTransactions] = useState<Array<ITransaction>>([]);
    const [topTransaction, setTopTransaction] = useState<ITransaction>();
    const [totalDepositing, setTotalDepositing] = useState<number>(0);
    const [totalDebiting, setTotalDebiting] = useState<number>(0);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [transactionTypes] = useState<Array<IEnumItem>>(getEnumList(TransactionType, TransactionTypeLabel));
    const [operationTypes] = useState<Array<IEnumItem>>(getEnumList(OperationType, OperationTypeLabel));

    useEffect(() => {
        if (!refreshRequired) return;

        setRefreshRequired(false);

        setTransactions([]);
        setTopTransaction(undefined);

        setLoading(true);

        serverFetch('transactions', { method: 'GET', queryParams: filter })
            .then((data: Array<ITransaction>) => {
                setTransactions(data);

                setTopTransaction(data[0]);

                let depositings = data.filter(
                    (d) => d.currency == Currency.Usd && (d.type == TransactionType.Depositing || d.type == TransactionType.Transfer)
                );
                let tmpTotalDepositing = 0;
                depositings.map((d) => {
                    tmpTotalDepositing += d.amount;
                });
                setTotalDepositing(tmpTotalDepositing);

                let debitings = data.filter((d) => d.currency == Currency.Usd && d.type == TransactionType.Debiting);
                let tmpTotalDebiting = 0;
                debitings.map((d) => {
                    tmpTotalDebiting += d.amount;
                });
                setTotalDebiting(tmpTotalDebiting);

                setLoading(false);
            })
            .catch((ex) => {
                exception(api, 'Ошибка получения транзакций', ex, () => d(userLoaded(undefined)));
                setLoading(false);
            });
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter || filter.userId !== userId) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onCancel = () => {
        if (!currentTransaction) return;

        serverFetch(`transactions/${currentTransaction.id}/cancel`, { method: 'POST' })
            .then(() => {
                onSelectChange([]);
                setRefreshRequired(true);

                onRefresh && onRefresh();
            })
            .catch((ex) => {
                exception(api, 'Ошибка отмены транзакции', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Select
                            key='transactionType'
                            placeholder='Тип операции'
                            value={filter?.type}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            allowClear
                            style={{ width: 200 }}
                            onChange={(value: any) => d(setFilter({ ...filter, type: value }, filterContext))}
                            options={transactionTypes.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />,
                        <Select
                            key='operationType'
                            placeholder='Тип платежа'
                            value={filter?.operationType}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            allowClear
                            style={{ width: 160 }}
                            onChange={(value: any) => d(setFilter({ ...filter, operationType: value }, filterContext))}
                            options={operationTypes.map((t) => {
                                return { value: t.value, label: t.label };
                            })}
                        />,
                        <RangePicker
                            style={{ width: 230 }}
                            allowEmpty={[true, true]}
                            key='date'
                            format='DD.MM.YYYY'
                            placeholder={['Операция с', 'до']}
                            cellRender={(current) => {
                                return <div className='ant-picker-cell-inner'>{(current as Dayjs).date()}</div>;
                            }}
                            value={[
                                filter?.createdFrom ? dayjs(filter?.createdFrom) : null,
                                filter?.createdTo ? dayjs(filter?.createdTo) : null,
                            ]}
                            onChange={(value) => {
                                if (!value) {
                                    d(
                                        setFilter(
                                            {
                                                ...filter,
                                                createdFrom: undefined,
                                                createdTo: undefined,
                                            },
                                            filterContext
                                        )
                                    );

                                    return;
                                }

                                d(
                                    setFilter(
                                        {
                                            ...filter,
                                            createdFrom:
                                                value && value[0]
                                                    ? dayjs(value[0]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                    : undefined,
                                            createdTo:
                                                value && value[1]
                                                    ? dayjs(value[1]).utc(true).set('hour', 0).set('minute', 0).set('second', 0).toString()
                                                    : undefined,
                                        },
                                        filterContext
                                    )
                                );
                            }}
                        />,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Отменить',
                        key: 'cancel',
                        disabled: !currentTransaction,
                        icon: <CloseOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.DeleteTransaction)) {
                                securityRestriction(api, [Permission.DeleteTransaction]);
                                return;
                            }

                            if (topTransaction?.id != currentTransaction?.id && topTransaction?.parentId != currentTransaction?.id) {
                                warning(api, 'Транзакции можно отменять только последовательно, начиная с последней');
                                return;
                            }

                            if (topTransaction?.id == currentTransaction?.id && currentTransaction?.parentId != undefined) {
                                let parent = transactions.find((t) => t.id == currentTransaction.parentId);
                                warning(
                                    api,
                                    `Для отмены данной транзакции отмените основную транзакцию оплаты счета #${parent?.billNumber}`
                                );
                                return;
                            }

                            modal.confirm({
                                title: `Отменить выбранную транзакцию?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onCancel();
                                },
                            });
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = transactions.find((o) => o.id == selectedRowKeys[0]);
            setCurrentTransaction(entity);
        } else {
            setCurrentTransaction(undefined);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<ITransaction> = [
            {
                width: 40,
                align: 'center',
                render: (_, record) => {
                    return record.currency == Currency.Rub ? (
                        <RubleFilledIcon style={{ fontSize: 20 }} />
                    ) : record.currency == Currency.Usd ? (
                        <UsdFilledIcon style={{ fontSize: 20 }} />
                    ) : (
                        <EuroFilledIcon style={{ fontSize: 20 }} />
                    );
                },
            },
            {
                title: 'Операция',
                width: 150,
                render: (_, record) => {
                    let icon = <></>;
                    let color = '';
                    let operation = <></>;

                    if (record.operationType == OperationType.Cash) {
                        operation = (
                            <Tag color='green' style={{ color: '#000000' }}>
                                Наличный
                            </Tag>
                        );
                    } else if (record.operationType == OperationType.NonCash) {
                        operation = (
                            <Tag color='var(--primary-color)' style={{ color: '#000000' }}>
                                Безналичный
                            </Tag>
                        );
                    }

                    if (record.type == TransactionType.DebtWriteOff) {
                        return (
                            <Tag color='var(--main-green)' style={{ fontWeight: 600, marginLeft: 5 }}>
                                {toFinanceString(record.amount, 2)}
                            </Tag>
                        );
                    } else {
                        if (record.type == TransactionType.Debiting) {
                            color = 'var(--main-red)';
                            icon = <MinusOutlined style={{ color: color }} />;
                        } else if (record.type == TransactionType.Refund) {
                            color = 'purple';
                            icon = <UndoOutlined style={{ color: color }} />;
                        } else if (record.type == TransactionType.Depositing) {
                            color = 'var( --main-green)';
                            icon = <PlusOutlined style={{ color: color }} />;
                        } else if (record.type == TransactionType.Transfer) {
                            color = 'blue';
                            icon = <SwapOutlined style={{ color: color }} />;
                        }

                        return (
                            <>
                                {icon}
                                <span style={{ color: color, fontWeight: 600, marginLeft: 5 }}>{toFinanceString(record.amount, 2)}</span>
                                <div>{operation}</div>
                            </>
                        );
                    }
                },
            },
            {
                title: 'ИНН',
                align: 'center',
                width: 120,
                render: (_, record) => {
                    return record.inn;
                },
            },
            {
                title: 'Комиссия %',
                align: 'center',
                width: 110,
                render: (_, record) => {
                    if (!record.commission) return undefined;

                    return record.commission && record.commission.toFixed(2);
                },
            },
            {
                title: 'Дата',
                align: 'center',
                width: 140,
                render: (_, record) => {
                    return record.createdOn && dayjs.utc(record.createdOn).local().format('DD.MM.YYYY HH:mm');
                },
            },
            {
                title: 'Инициатор',
                dataIndex: 'createdByName',
                width: 250,
                hidden: hideInitiator,
            },
            {
                title: 'Детали',
                render: (_, record) => {
                    return (
                        <div>
                            {record.type == TransactionType.Depositing && !record.comment ? (
                                <div style={{ marginLeft: 10 }}>Внесение</div>
                            ) : record.type == TransactionType.Debiting ? (
                                <div style={{ marginLeft: 10 }}>
                                    Списание по счету <span style={{ fontWeight: 600 }}>#{record.billNumber}</span>
                                </div>
                            ) : record.type == TransactionType.Refund ? (
                                <div style={{ marginLeft: 10 }}>Возврат</div>
                            ) : record.type == TransactionType.DebtWriteOff ? (
                                <div style={{ marginLeft: 10 }}>
                                    Списание задолженности <span style={{ fontWeight: 600 }}>"{record.debtName}" </span>
                                    {record.billNumber ? (
                                        <span>
                                            по счету <span style={{ fontWeight: 600 }}>#{record.billNumber}</span>
                                        </span>
                                    ) : undefined}
                                </div>
                            ) : (
                                <></>
                            )}

                            {record.comment && <div style={{ marginLeft: 10, marginBottom: 10 }}>{record.comment}</div>}
                        </div>
                    );
                },
            },
        ];

        const tableLoading = {
            spinning: loading,
            indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
        };

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={transactions}
                loading={tableLoading}
                pagination={false}
                rowSelection={
                    isViewMode
                        ? undefined
                        : {
                              selectedRowKeys: selectedIds,
                              onChange: onSelectChange,
                              columnWidth: 35,
                              type: 'radio',
                          }
                }
                onRow={(record: ITransaction) => {
                    return {
                        onClick: (event) => {
                            if (!record.id) return;

                            onSelectChange([record.id]);
                        },
                    };
                }}
                footer={() => (
                    <span
                        style={{
                            fontWeight: 700,
                            fontSize: 16,
                        }}
                    >
                        <span style={{ marginLeft: 5 }}>
                            Всего внесено
                            <Tag color='var(--main-green)' style={{ marginLeft: 5, fontSize: 18 }}>
                                +{toFinanceString(totalDepositing, 2)} $
                            </Tag>
                        </span>

                        <span style={{ marginLeft: 5 }}>
                            Всего потрачено
                            <Tag color='var(--main-red)' style={{ marginLeft: 5, fontSize: 18 }}>
                                -{toFinanceString(totalDebiting, 2)} $
                            </Tag>
                        </span>
                    </span>
                )}
                virtual={true}
                scroll={{ y: height ?? 0 + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 237px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            {contextHolder}
            {modalContextHolder}
        </div>
    );
};

export default Transactions;

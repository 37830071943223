import React, { useState, useEffect, useRef } from 'react';

import { useResizeObserver } from 'usehooks-ts';

import { useAppDispatch, useAppSelector } from '@store/hooks';

import { Table, Input, notification, Form, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import type { GetRef } from 'antd';

import { ReloadOutlined, CalendarOutlined, CheckCircleFilled } from '@ant-design/icons';

import Filter from '@controls/filter/filter';
import Toolbar from '@controls/toolbar/toolbar';

import { serverFetch } from '@src/core/server';

import { exception } from '@extensions/notification';
import { delayAction, toFinanceString } from '@extensions/utils';
import { userLoaded, setFilter } from '@store/actions';

import { IUserSession } from '@entities/user-session';
import { IUserFilter } from '@entities/user-filter';
import { IUserBalanceDetail } from '@entities/user-balance-detail';
import { IConsigneeTariffSetting } from '@entities/consignee-tariff-setting';

import { UnitType } from '@enums/unit-type';

import { TruckIcon, AirplaneIcon, BoxesIcon } from '@icons/index';
import { WeightType } from '@src/core/enums/weight-type';

const dayjs = require('dayjs');

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

type TableRef = GetRef<typeof Table>;

const filterContext: string = 'Balances';

const Balances = () => {
    const initFilter: IUserFilter = { isActive: true, isArchived: false };

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const tableRef = useRef<TableRef>(null);

    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<IUserFilter>((s) => s.filters[filterContext]);

    const d = useAppDispatch();

    const [api, notificationContextHolder] = notification.useNotification();

    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [balances, setBalances] = useState<Array<IUserBalanceDetail>>([]);
    const [currentBalance, setCurrentBalance] = useState<IUserBalanceDetail>();
    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [lastSelectedKey, setLastSelectedKey] = useState<React.Key>();

    const [tariffSettings, setTariffSettings] = useState<Array<IConsigneeTariffSetting>>([]);

    useEffect(() => {
        let cleanup = false;

        if (!refreshRequired) return;

        setSelectedIds([]);
        setCurrentBalance(undefined);
        setBalances([]);

        const fetchData = async () => {
            setLoading(true);

            let promises = [
                await serverFetch(`users/${userSession.userId}/balancedetails`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения балансов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`consignees/tariffsettings`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения настроек тарифа грузополучателей', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                let entities: Array<IUserBalanceDetail> = result[0][0];

                setBalances(entities);

                setTariffSettings(result[0][1]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();

        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!loading && lastSelectedKey) {
            tableRef.current?.scrollTo({ key: lastSelectedKey });
            setLastSelectedKey(undefined);
        }
    }, [loading]);

    useEffect(() => {
        if (!filter) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            setLastSelectedKey(selectedRowKeys[0]);

            let entity = balances.find((o) => o.userId == selectedRowKeys[0]);
            setCurrentBalance(entity);
        } else {
            setCurrentBalance(undefined);
        }
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Input
                            style={{ width: 150 }}
                            key='login'
                            placeholder='Логин'
                            value={filter?.login}
                            onChange={(data: any) => {
                                d(setFilter({ ...filter, login: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 300 }}
                            key='searchText'
                            placeholder='Контрагент'
                            value={filter?.searchText}
                            onChange={(data: any) => {
                                d(setFilter({ ...filter, searchText: data.target.value }, filterContext));
                            }}
                        />,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },

                    /*  {
                        label: 'Отправить уведомления о задолженности',
                        key: 'debtRemind',
                        icon: <NotificationOutlined />,
                        onClick: () => onDebtRemind(),
                    }, */
                ]}
                /*  farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]} */
            />
        );
    };

    const renderTable = () => {
        const columns: ColumnsType<IUserBalanceDetail> = [
            {
                title: 'Отгрузка',
                width: 130,
                align: 'center',
                render: (_, record) => {
                    return record.loadingOn && dayjs.utc(record.loadingOn).local().format('DD.MM.YYYY');
                },
            },
            {
                title: 'Страна',
                dataIndex: 'countryName',
                width: 130,
            },
            {
                title: '',
                align: 'center',
                width: 250,
                onCell: () => ({
                    style: {
                        paddingTop: 0,
                    },
                }),
                render: (_, record) => {
                    var diffQty: number = 0;
                    var useAdjustmentQty: boolean = false;

                    if (record.qty && record.unitType != UnitType.Weight) {
                        if (record.packages && record.packages.length > 0) {
                            var actualQty: number = 0;

                            record.packages &&
                                record.packages.map((p) => {
                                    actualQty += p.boxQty || 0;
                                });

                            diffQty = actualQty - record.qty;
                        } else {
                            useAdjustmentQty = true;
                        }
                    }

                    let awbLabel = <>AWB {record.cargoId && <AirplaneIcon style={{ fontSize: 14, marginLeft: 5, color: '#777777' }} />}</>;
                    return (
                        <Form colon={false} labelCol={{ span: 4 }} wrapperCol={{ span: 20 }} style={{ marginBottom: 5 }}>
                            <Form.Item
                                label={
                                    <Tooltip title='Количество коробок'>
                                        <BoxesIcon />
                                    </Tooltip>
                                }
                                className='country-form-item'
                                style={{ fontWeight: 600 }}
                            >
                                {record.qty}
                                {useAdjustmentQty ? (
                                    <span style={{ marginLeft: 2, marginRight: 2 }}>({record.adjustmentQty})</span>
                                ) : (
                                    diffQty != 0 && (
                                        <span style={{ color: diffQty > 0 ? 'green' : 'red', marginLeft: 2, marginRight: 2 }}>
                                            (<span>{diffQty > 0 && '+'}</span>
                                            <span>{diffQty}</span>)
                                        </span>
                                    )
                                )}
                                шт.
                            </Form.Item>
                            {record.loadingOn && (
                                <Form.Item
                                    label={
                                        <Tooltip title='Дата отгрузки'>
                                            <CalendarOutlined style={{ fontSize: 18 }} />
                                        </Tooltip>
                                    }
                                    className='country-form-item'
                                >
                                    <span style={{ backgroundColor: '#FFDD2D', padding: '0 4px' }}>
                                        {dayjs.utc(record.loadingOn).local().format('DD.MM.YYYY')}
                                    </span>
                                </Form.Item>
                            )}
                            {record.awbNumber && (
                                <Form.Item label={awbLabel} className='country-form-item'>
                                    <span style={{ backgroundColor: '#efefef', padding: '0 4px' }}>{record.awbNumber}</span>
                                </Form.Item>
                            )}
                            {record.truckNumber && (
                                <Form.Item
                                    label={
                                        <Tooltip title='Номер машины'>
                                            <TruckIcon />
                                        </Tooltip>
                                    }
                                    className='country-form-item'
                                >
                                    <span>{record.truckNumber}</span>
                                </Form.Item>
                            )}
                        </Form>
                    );
                },
            },
            {
                title: 'Вес Брутто',
                width: 120,
                align: 'center',
                render: (_: any, record) => {
                    if (record.unitType !== UnitType.Weight) return '';

                    let setting = tariffSettings.find((s) => s.consigneeId == record?.consigneeId && s.countryId == record?.countryId);

                    return (
                        record.grossWeight && (
                            <>
                                {record.grossWeight} <span style={{ fontWeight: 500 }}>кг</span>
                                {setting && setting.weightType == WeightType.Gross && (
                                    <Tooltip title='Расчетный вес'>
                                        <CheckCircleFilled style={{ color: '#FFDD2D', marginLeft: 5 }} />
                                    </Tooltip>
                                )}
                            </>
                        )
                    );
                },
            },
            {
                title: 'Объемный вес',
                width: 120,
                align: 'center',
                render: (_: any, record) => {
                    if (record.unitType !== UnitType.Weight) return '';

                    let setting = tariffSettings.find((s) => s.consigneeId == record?.consigneeId && s.countryId == record?.countryId);

                    return (
                        record.volumeWeight && (
                            <>
                                {record.volumeWeight} <span style={{ fontWeight: 500 }}>кг</span>
                                {(!setting || setting.weightType == WeightType.Volume) && (
                                    <Tooltip title='Расчетный вес'>
                                        <CheckCircleFilled style={{ color: '#FFDD2D', marginLeft: 5 }} />
                                    </Tooltip>
                                )}
                            </>
                        )
                    );
                },
            },
            {
                title: ' ',
                width: 30,
                align: 'center',
                onCell: () => ({
                    style: {
                        padding: 0,
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <div>
                            <div className='flight-price'>
                                <AirplaneIcon style={{ fontSize: 12 }} />
                            </div>
                            <div className='truck-price'>
                                <TruckIcon style={{ fontSize: 12 }} />
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'Тариф',
                width: 80,
                align: 'center',
                onCell: () => ({
                    style: {
                        padding: 0,
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <div>
                            <div className='flight-price'>
                                <span className='price-value'> {record.flightPrice}</span>
                            </div>
                            <div className='truck_-price'>
                                <span className='price-value'>{record.truckPrice}</span>
                            </div>
                        </div>
                    );
                },
            },
            {
                title: 'Сумма ($)',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <div style={{ color: record.totalPrice <= 0 ? 'red' : '' }}>
                            {record.totalPrice && toFinanceString(record.totalPrice, 2)}
                        </div>
                    );
                },
            },
            {
                title: 'Скидка ($)',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                        background: '#d2dcd8',
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <div style={{ color: record.totalPrice <= 0 ? 'red' : '' }}>
                            {record.totalPrice &&
                                record.totalPriceFinal &&
                                record.totalPrice > record.totalPriceFinal &&
                                toFinanceString(record.totalPrice - record.totalPriceFinal, 2)}
                        </div>
                    );
                },
            },
            {
                title: 'К оплате ($)',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record) => {
                    return <div style={{ color: record.totalPrice <= 0 ? 'red' : '' }}>{toFinanceString(record.totalPriceFinal, 2)}</div>;
                },
            },
            {
                title: 'Баланс ($)',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <div style={{ color: record.balance < 0 ? 'var(--main-red)' : 'var(--main-green)' }}>
                            {record.balance && toFinanceString(record.balance, 2)}
                        </div>
                    );
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={balances}
                pagination={false}
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'checkbox',
                    columnWidth: 35,
                }}
                onRow={(record) => {
                    return {
                        onClick: (event) => {
                            //   onSelectChange([record.id || '']);
                        },
                    };
                }}
                virtual={true}
                scroll={{ y: height ?? 0 + (showFilter ? 0 : 58) }}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 172px - ${filterHeight}px)` }}>
            {renderToolbar()}
            {/* { renderFilter()} */}
            {renderTable()}

            {notificationContextHolder}
        </div>
    );
};

export default Balances;
